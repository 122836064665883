import React from 'react';
import Navbar2 from '../components/navbar';
import Footer2 from '../components/footer';
import { Container, Label, Row } from 'reactstrap';

function ContactPage() {
  return (<>
    <Navbar2 />
    <Footer2 /></>
    
  );
}

export default ContactPage;
