import React, { useEffect } from "react";
import Slider from "react-slick";
import { Image } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

import bannerimg from '../img/path-makers/review5.png';
import bannerimg2 from '../img/path-makers/review3.png';
import bannerimg3 from '../img/path-makers/review6.png';
import { Container } from "reactstrap";
const cardsData = [
  {
    img: bannerimg,
    title: "Happiest Services",
    description:
      "It has been really amazing riding with path makers 😀 personally  they just made the ride most  comfortable for me and my family, just loved traveling with them. Owner of the brand is very polite and humble 😄",
    review: "Owner of the brand is very polite and humble 😄",
  },
  {
    img: bannerimg2,
    title: "Happiest Care",
    description:
      "Good service.Used Path Makers cab service from Gurgaon to Bareilly route.Cab service was very comfortable. They provided a cab which was clean and maintained. Driver was professional and polite. And the cab charges were reasonable.🤗🤗",
    review: " Overall, I am satisfied with their services and would recommend others to give them a chance.🤗🤗",
  },
  {
    img: bannerimg3,
    title: "Free Water Service",
    description:
      "I had an absolutely wonderful time traveling with Path Makers. The drivers' behavior was exemplary, and I felt safe and well-cared for throughout the trip. Without a doubt, Path Makers will be my number one choice for future travels.",
    review: "Path Makers will be my number one choice for future travels.",
  },
];
function Services() {
 
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    responsive: [
      {
        breakpoint: 600,
        settings: { slidesToShow: 1, slidesToScroll: 1, initialSlide: 2 },
      },
    ],
  };
  return (
    <>
    {/* <Container> */}
          {" "}
          <h3 className="text-center">
          Happiest Client
          </h3>
      <div className="main-tariff">
        {" "}
        {/* <div className="line">
          {" "}
          <h3 className="text-center">
          Happiest Client
          </h3>
        </div>{" "} */}
        <Slider {...settings}>
          {" "}
          {cardsData.map((d) => (
            <div className="inner-tariff" data-aos="fade-up">
              {" "}
              <div div className="service-slider">
                {" "}
                <div className="inner-box2">
                  {" "}
                  <p className="H2-text bold">{d.title} </p>{" "}
                  <div className="service-img-div">
                    {" "}
                    <Image src={d.img} className="service-img" />{" "}
                  </div>{" "}
                  <p className="Para-Text" data-aos="fade-up">
                    {d.description}
                  </p>{" "}
                  {/* <div className="slide-btn" data-aos="fade-up">
                    {" "}
                    <Link to="/enquire-now"> Enquire Now </Link>
                  </div>{" "} */}
                </div>{" "}
              </div>{" "}
            </div>
          ))}{" "}
        </Slider>{" "}
      </div>
      {/* </Container> */}
      {" "}
    </>
  );
}
export default Services;
