import React from "react";
import { Row, Col, Container, Input, Button } from "reactstrap";
import { FaLocationDot, FaPhone } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";

const Footer2 = () => {
  return (
    <>




      <div className="footer">
        <Row>
          <Col>
            <h3>Path-Makers</h3>
            <div>Ready to Book Your Ride?
              Experience the difference with Path-Makers Cab Booking. Start your journey today and discover hassle-free travel like never before.</div>
          </Col>
          <Col>
            <h3>Contact Us</h3>
            <div className="text-left m-3">
              <FaLocationDot />
              &nbsp;Bareilly Uttar Pradesh&nbsp; <FaLocationDot />
              &nbsp;Old Mahavir Nagar Janakpuri New Delhi
            </div><div className="text-left m-3">
            </div>
            <div className="text-left m-3">
              <FaPhone />
              &nbsp;<a href="tel:+918279506479">8279506479 </a>&nbsp;<FaPhone /><a href="tel:+918445894755">8445894755</a>
            </div>
            <div className="text-left m-3">
              <MdEmail />
              &nbsp;<a href="mailto:pathmakers08@gmail.com">pathmakers08@gmail.com</a>
            </div>
          </Col>
          {/* <Col>
            <h3>Registered Office</h3>
            <div className="text-left m-3">
              <FaLocationDot />
              &nbsp;Old Mahavir Nagar Janakpuri New Delhi
            </div>
            <div className="text-left m-3">
              <FaPhone />
              &nbsp;8279506479, 8445894755
            </div>
            <div className="text-left m-3">
              <MdEmail />
              &nbsp;pathmakers08@gmail.com
            </div>
          </Col> */}
        </Row>
        <hr></hr>
        <p>© 2024 Copyright: path-makers.com</p>
      </div>
    </>
  );
};

export default Footer2;