import RestMethod from "../ApiConfig/RestMethod";


export const addBook = async (data) => {
    let url = "/book-ride";
    try {
        const response = await RestMethod.POST(url, data);
        return response;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const AllBooking= async (leadstatus)=>{
    let url="/bookings"
    if(leadstatus){
        url+=`?status=${leadstatus}`
    }
    try {
        const response= await RestMethod.GET(url)
        return response.data
    } catch (error) {
        console.log("error detected while fetching data from api", error)
        return null
    }
}

export const allCarPrices = async () => {
    let url = "/car-price"
    try {
        const response = await RestMethod.GET(url);
        return response;
    } catch (error) {
        console.log(error);
        return null;
    }
};
export const oneCarPrices = async (id) => {
    let url = "/car-price/"+id
    try {
        const response = await RestMethod.GET(url);
        return response;
    } catch (error) {
        console.log(error);
        return null;
    }
};
export const addCarPrices = async (data) => {
    let url = "/car-prices";
    try {
        const response = await RestMethod.POST(url, data);
        return response;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const getNewRide = async () => {
    let url = "/newrides";
    try {
        const response = await RestMethod.GET(url);
       return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const changePassword = async (filter) => {
    let url = "/changepassword";
    if(filter){
        url+=filter
    }
    try {
        const response = await RestMethod.GET(url);
       return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};


export const updateBookById = async (id, data) => {
    let url = "/update/" + id;
    try {
        const response = await RestMethod.PUT(url, data);
        return response;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const deleteBookById = async (id) => {
    let url = "/deleteById/" + id;
    try {
        const response = await RestMethod.DELETE(url);
        return response;
    } catch (error) {
        console.log(error);
        return null;
    }
};


export const allPool = async () => {
    let url = "/car-pool-all"
    try {
        const response = await RestMethod.GET(url);
        return response;
    } catch (error) {
        console.log(error);
        return null;
    }
};
export const allPoolbyStatus = async (status) => {
    let url = "/car-pool?status="+status
    try {
        const response = await RestMethod.GET(url);
        return response;
    } catch (error) {
        console.log(error);
        return null;
    }
};
export const oneCarPool= async (id) => {
    let url = "/car-pool/"+id
    try {
        const response = await RestMethod.GET(url);
        return response;
    } catch (error) {
        console.log(error);
        return null;
    }
};
export const addPool = async (data) => {
    let url = "/car-pool";
    try {
        const response = await RestMethod.POST(url, data);
        return response;
    } catch (error) {
        console.log(error);
        return null;
    }
};
export const addNewPool = async (data) => {
    let url = "/car-pool-add";
    try {
        const response = await RestMethod.POST(url, data);
        return response;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const updateCarPool= async (id,data) => {
    let url = "/car-pool/"+id
    try {
        const response = await RestMethod.PUT(url, data);
        return response;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const exportData= async () => {
    let url = "/export-data"
    try {
        const response = await RestMethod.GET(url);
        return response;
    } catch (error) {
        console.log(error);
        return null;
    }
};