import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
} from "reactstrap";
// import Aos from "aos";
// import "aos/dist/aos.css";
// import Detailedview from "../components/detailedview";

import DateTime from "react-datetime";
import { Link, useNavigate } from "react-router-dom";
import { getUserlocal } from "../_helper/ApiConfig/API_AUTH";
import {
  AllBooking,
  addCarPrices,
  addPool,
  allCarPrices,
  allPoolbyStatus,
  getNewRide,
  oneCarPool,
  oneCarPrices,
  updateBookById,
  updateCarPool,
} from "../_helper/CallApi/bookService";
import { ToastContainer, toast } from "react-toastify";
import { getAllCities, getMatrix } from "../_helper/CallApi/citiesservice";
import moment from "moment";

function CarPool(props) {
  const [leads, setLeads] = useState();
  const [id, setId] = useState();
  const [carname, setCar] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const [totalfareprice, setTotalfareprice] = useState();
  const [availablesheet, setAvailablesheet] = useState();
  const [comment, setComment] = useState();
  const [authUser, setAuthUser] = useState(getUserlocal());
  const navigate = useNavigate();

  // setInterval(()=>{
  //   (async()=>{
  //     const response=await getNewRide()
  //     console.log(response);
  //   })();
  // },300000)

  useEffect(() => {
    // Aos.init({ duration: 1000 });
    (async () => {
      if (!showAlert) {
        const data = await allPoolbyStatus("0");
        if (data?.data?.status == 1) {
          setLeads(data?.data.data);
        } else {
          setLeads([]);
        }
      }
    })();
  }, [props?.tab, showAlert]);

  // useEffect(()=>{
  //   setLeads(leadst?.filter((i)=>(i.phone.includes(props?.searchvalue)||i.name.includes(props?.searchvalue))))
  // },[props?.searchvalue])
  const onChangeStatus = async (idx) => {
    // let obj={carname,price,extraprice,comment}
    setId(idx);
    let response = await oneCarPool(idx);
    if (response?.data?.status == 1) {
      setCar(response?.data?.data.car);
      setTotalfareprice(response?.data?.data.totalfareprice);
      setAvailablesheet(response?.data?.data.availablesheet);
      setDropL(response?.data?.data.droplocation);
      setPickup(response?.data?.data.pickuplocation);
      setSelectedDate(new Date(response?.data?.data.datetime));
      // setComment(response?.data?.data.comment)
      setShowAlert(true);
      // toast(response.data.message)
    } else {
      toast("something went wronge");
    }
  };
  const onDelete = async (idx) => {
    // let obj={carname,price,extraprice,comment}
    setId(idx);
    let response = await updateCarPool(idx, { status: 1 });
    if (response?.data?.status == 1) {
      // setShowAlert(true)
      (async () => {
        if (!showAlert) {
          const data = await allPoolbyStatus("0");
          if (data?.data?.status == 1) {
            setLeads(data?.data.data);
          } else {
            setLeads([]);
          }
        }
      })();
      toast(response.data.message);
    } else {
      toast("something went wronge");
    }
  };
  // const onChange = async (e) => {
  //   e.preventDefault()
  //   // let obj={carname,price,extraprice,comment}
  //   if(!carname){

  //     toast("car , price and extra fare price are requird")
  //     return
  //   }
  //   let response = await addPool({})
  //   if (response?.data?.status == 1) {
  //     setShowAlert(false)
  //     toast(response.data.message)
  //   } else {
  //     toast("something went wronge")
  //   }
  // }
  const [pickupList, setPickupList] = useState([]);
  const [dropList, setDropList] = useState([]);
  // const [startDate, setStartDate] = useState(new Date());
  // const [time, setTime] = useState("12:00");
  const [pickupl, setPickupl] = useState("");
  const [dropLo, setDropLo] = useState("");
  const [datamap, setDataMap] = useState("");
  const [pickup, setPickup] = useState("");
  const [dropL, setDropL] = useState("");
  const [pickupapi, setPickupapi] = useState("");
  const [dropLapi, setDropLapi] = useState("");
  const [dt, setDt] = useState(0);
  const [du, setDu] = useState(0);
  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    (async () => {
      if (pickupapi && pickupapi.length > 3) {
        let response = await getAllCities("q=" + pickupapi);
        if (response?.data?.data?.length > 0) {
          setPickupList(response.data.data.map((i) => i.cityname));
        } else {
          setPickupList([]);
        }
      }
    })();
  }, [pickupapi]);
  useEffect(() => {
    (async () => {
      if (dropLapi && dropLapi.length > 3) {
        let response = await getAllCities("q=" + dropLapi);
        if (response?.data?.data?.length > 0) {
          setDropList(response.data.data.map((i) => i.cityname));
        } else {
          setDropList([]);
        }
      }
    })();
  }, [dropLapi]);

  useEffect(() => {
    setPickup(pickupl);
  }, [pickupl]);
  useEffect(() => {
    setDropL(dropLo);
  }, [dropLo]);

  const handleOpenPopup = async (e) => {
    e.preventDefault();
    if (!totalfareprice || !availablesheet || !carname || !pickup || !dropL) {
      toast("all fields are requird");
      return;
    }
    if (pickup && dropL) {
      let distance = await getMatrix(dropL, pickup);
      if (distance && distance.data?.status == 1) {
        setDt(distance.data.distance);
        setDu(distance.data.duration);
        setDataMap(distance.data.data);
        let obj = {
          id: id,
          pickuplocation: distance.data.data.origin_addresses[0].substring(
            0,
            distance.data.data.origin_addresses[0].length - 7
          ),
          datetime: selectedDate,
          droplocation: distance.data.data.destination_addresses[0].substring(
            0,
            distance.data.data.destination_addresses[0].length - 7
          ),
          totalfareprice: totalfareprice,
          availablesheet: availablesheet,
          totalfaredistance: distance.data.distance,
          car: carname,
        };
        let response = await addPool(obj);
        if (response?.data?.status == 1) {
          setShowAlert(false);
          toast(response.data.message);
        } else {
          toast("something went wronge");
        }
      }
    }
  };
  const handleChangeDate = (date) => {
    setSelectedDate(date);
  };
  // const handleClosePopup = () => {
  //   setIsPopupOpen(false);
  // };
  const handleSuggep = (value) => {
    setPickup(value);
    setPickupapi("");
    setPickupList([]);
  };

  const handlePick = (value) => {
    setPickup(value);
    setPickupapi(value);
  };

  const handleDrop = (value) => {
    setDropL(value);
    setDropLapi(value);
  };

  const handleSuggeL = (value) => {
    setDropL(value);
    setDropLapi("");
    setDropList([]);
  };

  // const handleSelectCar = (carType) => {
  //   setSelectedCar(carType);
  //   // handleClosePopup();
  // };
  return (
    <>
      {showAlert && (
        <div className="car-selection-popup-2">
          <div className="popup-content ">
            {/* <div className="input-block"> */}
            {/* <h6>Enter your mobile number for further<br /> ride confirmation</h6> */}
            <label>Enter Car Name</label>
            <Input
              type="text"
              value={carname}
              onChange={(e) => setCar(e.target.value)}
              placeholder="Enter car Name"
            />
            <label>Enter far Rate</label>
            <Input
              type="text"
              value={totalfareprice}
              onChange={(e) => setTotalfareprice(e.target.value)}
              placeholder="Price"
            />
            <label>Enter available sheet</label>
            <Input
              type="text"
              value={availablesheet}
              onChange={(e) => setAvailablesheet(e.target.value)}
              placeholder="available sheets"
            />
            {/* <Input type='text' value={comment} onChange={(e) => setComment(e.target.value)} placeholder='comments' /> */}
            <div className="input-block">
              <label>Pickup Location</label>
              <div className="group-img">
                <Input
                  type="text"
                  value={pickup}
                  onChange={(e) => handlePick(e.target.value)}
                  className="form-control"
                  placeholder="Enter City, Airport, or Address"
                />
                {pickupList.length > 0 && (
                  <ul className="suggestion-list">
                    {pickupList.map((suggestion, index) => (
                      <li
                        key={index}
                        className="suggestion-item"
                        onClick={() => handleSuggep(suggestion)}
                      >
                        {suggestion}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>

            <div className="input-block">
              <label>Pickup Date Time</label>
            </div>
            <div className="input-block-wrapp">
              <div className="input-block date-widget">
                <div className="group-img">
                  <DateTime
                    // className="form-control "
                    value={selectedDate}
                    onChange={handleChangeDate}
                    dateFormat="MM/DD/YYYY"
                    timeFormat="HH:mm"
                    inputProps={{
                      className: "dateTimeInput",
                      style: {
                        background: "#fcfbfb",
                        borderRadius: 5,
                        border: "1px solid #f4f4f4",
                        boxShadow: "none",
                        color: "#201f1d",
                      },
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="input-block">
              <label>Drop Location</label>
              <div className="group-img">
                <Input
                  type="text"
                  value={dropL}
                  onChange={(e) => handleDrop(e.target.value)}
                  className="form-control"
                  placeholder="Enter City, Airport, or Address"
                />
                {dropList.length > 0 && (
                  <ul className="suggestion-list">
                    {dropList.map((suggestion, index) => (
                      <li
                        key={index}
                        className="suggestion-item"
                        onClick={() => handleSuggeL(suggestion)}
                      >
                        {suggestion}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
            {/* <div className="input-block"> */}
            <div className="d-flex justify-content-between mt-3">
              <Button
                className="btn search-button"
                style={{ backgroundColor: "red" }}
                onClick={() => setShowAlert(false)}
              >
                No
              </Button>
              <Button
                className="btn search-button"
                style={{ backgroundColor: "#00ed27" }}
                onClick={handleOpenPopup}
              >
                Yes
              </Button>
            </div>
            {/* </div> */}
            {/* </div> */}
          </div>
        </div>
      )}
      <div className="m-1 sticky-table-container ">
        {leads && leads.length > 0 ? (
          <>
            <Table striped responsive data-aos="fade-up">
              <thead>
                <tr className="text-center">
                  <th>S.No.</th>
                  <th>Car</th>
                  <th>Pickup-location</th>
                  <th>Drop-location</th>
                  <th>Datetime</th>
                  <th>Total-price</th>
                  <th>Total-distance</th>
                  <th>Total-Sheet</th>
                  <th>{"Action"}</th>
                </tr>
              </thead>
              {leads.map((i, index) => (
                <tbody>
                  <tr>
                    <td  className="text-center">{index + 1}</td>
                    <td  className="text-center">{i.car}</td>
                    <td  className="text-center">{i.pickuplocation}</td>
                    <td  className="text-center">{i.droplocation}</td>
                    <td  className="text-center">{moment(i.datetime).format("YYYY-MM-DD hh:mm:ss")}</td>
                    <td  className="text-center">{i.totalfareprice}</td>
                    <td  className="text-center">{i.totalfaredistance}</td>
                    <td  className="text-center">{i.availablesheet}</td>
                    <td  className="text-center">
                      <Button
                        color="info"
                        onClick={async (e) => {
                          e.preventDefault();
                          // navigate(`/edit-lead/${i.id}`)
                          // setId(i.id)
                          // toggle4()
                          await onChangeStatus(i.id);
                        }}
                        size="sm"
                      >
                        Edit
                      </Button>

                      <Button
                        color="info"
                        onClick={async (e) => {
                          e.preventDefault();
                          // navigate(`/edit-lead/${i.id}`)
                          // setId(i.id)
                          // toggle4()
                          await onDelete(i.id);
                        }}
                        size="sm"
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                </tbody>
              ))}
            </Table>
            <Button
              color="info"
              onClick={async (e) => {
                e.preventDefault();
                setId("");
                setCar("");
                setTotalfareprice("");
                setAvailablesheet("");
                setDropL("");
                setPickup("");
                setSelectedDate(new Date());
                setShowAlert(true);
              }}
              size="sm"
            >
              Add
            </Button>
          </>
        ) : (
          <>
            <Button
              color="info"
              onClick={async (e) => {
                e.preventDefault();
                setId("");
                setCar("");
                setTotalfareprice("");
                setAvailablesheet("");
                setDropL("");
                setPickup("");
                setSelectedDate(new Date());
                setShowAlert(true);
              }}
              size="sm"
            >
              Add
            </Button>
          </>
        )}
      </div>
    </>
  );
}

export default CarPool;
