import React, { useEffect, useState } from "react";
import { Table, Button, Modal, ModalHeader, ModalBody, Input, Label } from "reactstrap";
// import Aos from "aos";
// import "aos/dist/aos.css";
// import Detailedview from "../components/detailedview";
import { Link, useNavigate } from "react-router-dom";
import { getUserlocal } from "../_helper/ApiConfig/API_AUTH";
import { AllBooking, addCarPrices, allCarPrices, getNewRide, oneCarPrices, updateBookById } from "../_helper/CallApi/bookService";
import { ToastContainer, toast } from 'react-toastify';

function CarPrices(props) {
  const [leads, setLeads] = useState()
  const [carname, setCar] = useState()
  const [price, setPrice] = useState()
  const [extraprice, setExtra] = useState()
  const [showAlert, setShowAlert] = useState(false);
  const [comment, setComment] = useState()
  const [authUser, setAuthUser] = useState(getUserlocal())
  const navigate = useNavigate()
  // setInterval(()=>{
  //   (async()=>{
  //     const response=await getNewRide()
  //     console.log(response);
  //   })();
  // },300000)

  useEffect(() => {
    // Aos.init({ duration: 1000 });
    (async () => {
      if (!showAlert) {
        const data = await allCarPrices()
        if (data?.data?.status == 1) {
          setLeads(data?.data.data)
        } else {
          setLeads([])
        }
      }
    })();
  }, [props?.tab, showAlert]);

  // useEffect(()=>{
  //   setLeads(leadst?.filter((i)=>(i.phone.includes(props?.searchvalue)||i.name.includes(props?.searchvalue))))
  // },[props?.searchvalue])
  const onChangeStatus = async (id) => {
    // let obj={carname,price,extraprice,comment}
    let response = await oneCarPrices(id)
    if (response?.data?.status == 1) {
      setCar(response?.data?.data.carname)
      setPrice(response?.data?.data.price)
      setExtra(response?.data?.data.extraprice)
      setComment(response?.data?.data.comment)
      setShowAlert(true)
      // toast(response.data.message)
    } else {
      toast("something went wronge")
    }
  }
  const onChange = async (e) => {
    e.preventDefault()
    // let obj={carname,price,extraprice,comment}
    if (!carname || !price || !extraprice) {

      toast("car , price and extra fare price are requird")
      return
    }
    let response = await addCarPrices({ carname, price, extraprice, comment })
    if (response?.data?.status == 1) {
      setShowAlert(false)
      toast(response.data.message)
    } else {
      toast("something went wronge")
    }
  }
  return (<>{showAlert && (
    <div className="car-selection-popup-2">
      <div className="popup-content-2 ">
        {/* <div className="input-block"> */}
        {/* <h6>Enter your mobile number for further<br /> ride confirmation</h6> */}
        <Label>Enter Car Name</Label>
        <Input type='text' value={carname} onChange={(e) => setCar(e.target.value)} placeholder='Enter car Name' />
        <Label className="mt-1">Enter Price Per km</Label>
        <Input type='text' value={price} onChange={(e) => setPrice(e.target.value)} placeholder='Price' />
        <Label className="mt-1">Enter Extra Fare Price Per km</Label>
        <Input type='text' value={extraprice} onChange={(e) => setExtra(e.target.value)} placeholder='Extra far price' />
        <Label className="mt-1">Enter Comment</Label>
        <Input type='text' value={comment} onChange={(e) => setComment(e.target.value)} placeholder='comments' />
        {/* <div className="input-block"> */}
        <div className="d-flex justify-content-between mt-3">
          <Button className="btn search-button" style={{ backgroundColor: "red" }} onClick={() => setShowAlert(false)}>No</Button>
          <Button className="btn search-button" style={{ backgroundColor: "#00ed27" }} onClick={onChange} >Yes</Button>
        </div>
        {/* </div> */}
        {/* </div> */}
      </div>
    </div>
  )}
    <div className="m-1 sticky-table-container ">
      {leads && leads.length > 0 ? (<>
        <Table striped responsive data-aos="fade-up">
          <thead>
            <tr className="text-center">
              <th>S.No.</th>
              <th>Car</th>
              <th>Rate</th>
              <th>Extra Far Rate</th>
              <th>Comment</th>
              <th>{"Action"}</th>
            </tr>
          </thead>
          {leads.map((i, index) => (
            <tbody>
              <tr>
                <td className="text-center">{index + 1}</td>
                <td className="text-center">{i.carname}</td>
                <td className="text-center">{i.price}</td>
                <td className="text-center">{i.extraprice}</td>
                <td className="text-center">{i.comment}</td><td>
                  <Button color="info" className="center" onClick={async (e) => {
                    e.preventDefault()
                    // navigate(`/edit-lead/${i.id}`)
                    // setId(i.id)
                    // toggle4()
                    await onChangeStatus(i.id)
                  }} size="sm">
                    Edit
                  </Button>
                </td>
              </tr>
            </tbody>
          ))}
        </Table><Button color="info" onClick={async (e) => {
          e.preventDefault()
          // navigate(`/edit-lead/${i.id}`)
          // setId(i.id)
          // toggle4()
          // await onChangeStatus()
          setCar("")
          setPrice("")
          setExtra("")
          setComment("")
          setShowAlert(true)
        }} size="sm">
          Add
        </Button></>
      ) : (
        <><Button color="info" onClick={async (e) => {
          e.preventDefault()
          // navigate(`/edit-lead/${i.id}`)
          // setId(i.id)
          // toggle4()
          // await onChangeStatus()

          setCar("")
          setPrice("")
          setExtra("")
          setComment("")
          setShowAlert(true)
        }} size="sm">
          Add
        </Button></>
      )}

      {/* <Modal size="lg" style={{maxWidth: '700px', width: '100%'}} isOpen={modal} toggle={toggle3} backdrop="static">
        <ModalHeader toggle={toggle3} className="adddeal-popup">
          Detailed View
        </ModalHeader>
        <ModalBody className="adddeal-popup">
          <Detailedview id={id} />
        </ModalBody>
      </Modal> */}

      {/* <Modal isOpen={modal2} size="lg" toggle={toggle4} className="text-center" backdrop="static">
        <ModalHeader toggle={toggle4} className="adddeal-popup text-center">
          Edit User Data
        </ModalHeader>
        <ModalBody className="adddeal-popup">
          <Dealform id={id}/>
        </ModalBody>
      </Modal> */}
    </div></>
  );
}

export default CarPrices;
