import React, { useEffect, useState } from 'react';
import suv1 from '../img/sedan1.jpeg';
import mini1 from '../img/mini.png';
import sedan1 from '../img/swift1.jpeg';
import { Button, Input } from 'reactstrap';
import { addBook, allCarPrices } from '../_helper/CallApi/bookService';
import { ToastContainer, toast } from 'react-toastify';
const CarSelectionPopup = ({ onClose, onSelect, dt, du, pickup, dropL, datamap, selectedDate }) => {
    const [showAlert, setShowAlert] = useState(false);
    const [mobile, setMobile] = useState("");
    const [selectedCar, setSelectedCar] = useState("Mini");
    const [miniextra, setMiniExtra] = useState(5)
    const [suvextra, setSuvExtra] = useState(7)
    const [sedanextra, setSedanExtra] = useState(10)
    const [mini, setMini] = useState("10")
    const [suv, setSuv] = useState("0")
    const [sedan, setSedan] = useState("15")
    const handleSelectCar = (carType) => {
        setSelectedCar(carType);
        onSelect(carType);
    };
    console.log(mini, suv);
    useEffect(() => {
        (async () => {
            let responce = await allCarPrices()
            if (responce && responce.data.status == 1) {
                console.log(responce.data.data, responce.data.data.filter((i) => (i.carname.toLowerCase() == "suv"))[0]?.price);
                setMini((responce.data.data.filter((i) => (i.carname.toLowerCase() == "mini"))[0]?.price) || mini)
                setMiniExtra((responce.data.data.filter((i) => (i.carname.toLowerCase() == "mini"))[0]?.extraprice) || miniextra)
                setSuv((responce.data.data.filter((i) => (i.carname.toLowerCase() == "suv"))[0]?.price) || suv)
                setSuvExtra((responce.data.data.filter((i) => (i.carname.toLowerCase() == "suv"))[0]?.extraprice) || suvextra)
                setSedan((responce.data.data.filter((i) => (i.carname.toLowerCase() == "sedan"))[0]?.price) || sedan)
                setSedanExtra((responce.data.data.filter((i) => (i.carname.toLowerCase() == "sedan"))[0]?.extraprice) || sedanextra)
            }
        })()
    }, [])
    const onBook = async (e) => {
        e.preventDefault()
        let obj = {
            number: mobile,
            pickuplocation: datamap.origin_addresses[0].substring(0, datamap.origin_addresses[0].length - 7),
            datetime: selectedDate,
            droplocation: datamap.destination_addresses[0].substring(0, datamap.destination_addresses[0].length - 7),
            totalfareprice: selectedCar === 'Mini' ? mini * +dt.split(" ")[0] : selectedCar === 'SUV' ? suv * +dt.split(" ")[0] : sedan * +dt.split(" ")[0],
            totalfaredistance: dt,
            car: selectedCar,
        };
        let booking = await addBook(obj)
        if (booking?.data?.status == 1) {
            toast.success("booking in progress we will contact you in 5 min")
            // toast("booking in progress we will contact you in 5 min")
            setShowAlert(false)
            onClose()
        } else if (booking?.data?.status == 0) {
            toast.warn(booking?.data.message)
        } else {
            toast.error("service not avalable")
        }
    }
    return (<> {showAlert && (
        <div className="car-selection-popup-2">
            <div className="popup-content-2 ">
                {/* <div className="input-block"> */}
                <h6>Enter your mobile number for further<br /> ride confirmation</h6>
                <Input type='text' value={mobile} onChange={(e) => setMobile(e.target.value)} placeholder='Enter mobile number' />
                {/* <div className="input-block"> */}
                <div className="d-flex justify-content-between mt-3">
                    <Button className="btn search-button" style={{ backgroundColor: "red" }} onClick={() => setShowAlert(false)}>Cancel</Button>
                    <Button className="btn search-button" style={{ backgroundColor: "#00ed27" }} onClick={onBook}>Continue</Button>
                </div>
                {/* </div> */}
                {/* </div> */}
            </div>
        </div>
    )}
        <div className="car-selection-popup">
            <div className="popup-content">
                <div className='d-flex justify-content-between' style={{ fontStyle: "italic" }}>
                    <h4>Select a Car</h4><h4 >Total price-{((selectedCar == "Mini" ? mini : selectedCar === 'Sedan' ? sedan : suv) * +dt.split(" ")[0])?.toFixed(2)} Rs.</h4>
                </div>
                <p style={{ color: "red" }}><b>*prices may vary based on your location</b></p><span><h6>From {datamap.origin_addresses[0].substring(0, datamap.origin_addresses[0].length - 7)} to {datamap.destination_addresses[0].substring(0, datamap.destination_addresses[0].length - 7)}</h6>
                    <label className='total-fare'>Your total fare is {dt} and time {du}</label></span>
                <div className="car-options carpooloption">
                    <div className={`car-option-1 carpooloption ${selectedCar === 'Mini' ? 'selected' : ''}`} onClick={() => handleSelectCar('Mini')}>
                        <div className="input-block-1">
                            <img className='car-options-select' src={mini1} alt="Mini" />
                        </div>
                        <div className="input-block-1">    <div className='m-2'>
                            <label><b>Mini</b></label>
                            {/* <label className='total-fare'>Your total fare is {dt} and time {du}</label> */}
                            <p>Total price <b>{(mini * +dt.split(" ")[0])?.toFixed(2)}</b> Rs.<br />Extra Far {miniextra} Rs./km</p>
                            {/* <img className='car-options-select' src={servicesIcon} alt="Mini" /> */}
                        </div></div>
                        {/* <p>Your total fare is 275 km</p> */}
                    </div>
                    <div className={`car-option-1 carpooloption ${selectedCar === 'Sedan' ? 'selected' : ''}`} onClick={() => handleSelectCar('Sedan')}>
                        <div className="input-block-1">
                            <img className='car-options-select' src={sedan1} alt="Sedan" />
                        </div>
                        <div className="input-block-1">
                            <div className='m-2'>
                                <label><b>Sedan</b></label>
                                {/* <label className='total-fare'>Your total fare is {dt} and time {du}</label> */}
                                <p>Total price <b>{(+sedan * +dt.split(" ")[0])?.toFixed(2)}</b> Rs.<br />Extra Far {sedanextra} Rs./km</p>
                                {/* <p>Extra Far {sedanextra} Rs./km</p> */}
                                {/* <img className='car-options-select' src={servicesIcon} alt="Mini" /> */}</div>
                        </div>
                        {/* <p>Your total fare is 275 km</p> */}
                    </div>
                    <div className={`car-option-1 carpooloption ${selectedCar === 'SUV' ? 'selected' : ''}`} onClick={() => handleSelectCar('SUV')}>
                        <div className="input-block-1">
                            <img className='car-options-select' src={suv1} alt="SUV" />
                        </div>
                        <div className="input-block-1">
                            <div className='m-2'>
                                <label><b>SUV</b></label>
                                {/* <label className='total-fare'>Your total fare is {dt} and time {du}</label> */}
                                <p>Total price <b>{(suv * +dt.split(" ")[0])?.toFixed(2)}</b> Rs.<br />Extra Far {suvextra} Rs./km</p>
                                {/* <p>Extra Far {suvextra} Rs./km</p> */}
                                {/* <img className='car-options-select' src={servicesIcon} alt="Mini" /> */}</div>
                        </div>
                        {/* <p>Your total fare is 275 km</p> */}
                    </div>

                </div>

                <div className='d-flex justify-content-between'>
                    <Button className="btn search-button " style={{ backgroundColor: "red" }} onClick={onClose}>Close</Button>
                    <Button className="btn search-button" onClick={() => setShowAlert(true)}>Book</Button>
                </div>
            </div>

        </div>   </>
    );
};




export default CarSelectionPopup;
