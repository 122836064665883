import React, { useState, useEffect } from "react";
// import Aos from "aos";
// import "aos/dist/aos.css";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  FormText,
} from "reactstrap";
import { FaUser, FaLock } from "react-icons/fa"; // Importing icons from React Icons
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { toast } from 'react-toastify';
import { setSession } from "../_helper/ApiConfig/API_AUTH";
import { signIn } from "../_helper/CallApi/authService";

function Login() {
  // useEffect(() => {
  //   Aos.init({ duration: 1000 });
  // }, []);
  const navigate = useNavigate()
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Perform login logic here, like sending data to a server
    let obj = { email: email, password: password };
    if (!email || !password) {
      toast("all fields are required !");
      return;
    }
    let res = await signIn(obj);
    if (res?.status == 1) {
      setSession(res.data.token);
      navigate("/booking");
      toast(res?.message);
      return;
    }
    setSession();
    toast(res?.message);
    return;
  };

  return (
    <Container className="login-container ">
      <Helmet>
        <title>Path-Makers Login</title>
      </Helmet>
      <Row className="justify-content-center align-items-center">
        <Col md={6}>
          <div className="shadow-lg p-5 mb-5 rounded" data-aos="fade-up">
            <h2 className="text-center m-4">Login</h2>
            <Form >
              <FormGroup className="mb-3">
                <Label for="email" className="input-label">
                  <FaUser className="icon" /> Email
                </Label>
                <Input
                  type="email"
                  id="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormGroup>
              <FormGroup className="mb-3">
                <Label for="password" className="input-label">
                  <FaLock className="icon" /> Password
                </Label>
                <Input
                  type="password"
                  id="password"
                  placeholder="Enter your password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </FormGroup>

              <Button block className="all-btn mt-5" onClick={handleSubmit}>
                Login
              </Button>

              {/* <FormText className="mt-3 text-center">
                Don't have an account? <Link to="/signup">signup here</Link>
              </FormText> */}
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Login;
