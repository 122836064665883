import RestMethod from "../ApiConfig/RestMethod";


export const addCity = async (data) => {
    let url = "/create";
    try {
        const response = await RestMethod.POST(url, data);
        return response;
    } catch (error) {
        console.log(error);
        return null;
    }
};


export const getAllCities = async (q) => {
    let url = "/getAll?" + q;
    try {
        const response = await RestMethod.GET(url);
        return response;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const getMatrix = async (dt,pc) => {
    let url = "/matrix?dt="+dt+"&pc="+pc;
    try {
        const response = await RestMethod.GET(url);
       return response;
    } catch (error) {
        console.log(error);
        return null;
    }
};


export const updateBookById = async (id, data) => {
    let url = "/updateById/" + id;
    try {
        const response = await RestMethod.PUT(url, data);
        return response;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const deleteBookById = async (id) => {
    let url = "/deleteById/" + id;
    try {
        const response = await RestMethod.DELETE(url);
        return response;
    } catch (error) {
        console.log(error);
        return null;
    }
};
