import React, { useEffect, useState } from "react";
import { Table, Button, Modal, ModalHeader, ModalBody } from "reactstrap";
// import Aos from "aos";
// import "aos/dist/aos.css";
// import Detailedview from "../components/detailedview";
import { Link, useNavigate } from "react-router-dom";
import { getUserlocal } from "../_helper/ApiConfig/API_AUTH";
import { AllBooking, getNewRide, updateBookById } from "../_helper/CallApi/bookService";
import { ToastContainer, toast } from 'react-toastify';
import moment from "moment";

function Alluserdata(props) {
  const [leads, setLeads] = useState()
  const [authUser, setAuthUser] = useState(getUserlocal())
  const navigate = useNavigate()
  // setInterval(()=>{
  //   (async()=>{
  //     const response=await getNewRide()
  //     console.log(response);
  //   })();
  // },300000)

  useEffect(() => {
    // Aos.init({ duration: 1000 });
    (async () => {
      const data = await AllBooking(props.tab)
      if (data?.status == 1) {
        setLeads(data?.data)
      } else {
        setLeads([])
      }
    })();
  }, [props?.tab, props?.reload, props?.newrides]);
  // useEffect(()=>{
  //   setLeads(leadst?.filter((i)=>(i.phone.includes(props?.searchvalue)||i.name.includes(props?.searchvalue))))
  // },[props?.searchvalue])
  const onChangeStatus = async (id, value) => {
    let response = await updateBookById(id, { status: value })
    if (response.data) {
      toast(response.data.message)
    } else {
      toast("something went wronge")
    }
    if (props.reload) {
      props.setReload(0)
    } else {
      props.setReload(1)
    }
  }
  // Modal open state
  // const [modal, setModal] = useState(false);
  // const [modal2, setModal2] = useState(false);
  // const [id, setId] = useState();
  // Toggle for Modal
  // const toggle3 = () => setModal(!modal);
  // const toggle4 = () => setModal2(!modal2);
  return (
    <div className="m-1 sticky-table-container ">
      {leads && leads.length > 0 ? (
        <Table striped responsive hover data-aos="fade-up">
          <thead>
            <tr className="text-center">
              <th  className="text-center">S.No.</th>
              <th  className="text-center">Number</th>
              <th  className="text-center">Pickup-location</th>
              <th  className="text-center">Drop-location</th>
              <th  className="text-center">Datetime</th>
              <th  className="text-center">Total-price</th>
              <th  className="text-center">Total-distance</th>
              <th  className="text-center">Car</th>
              <th  className="text-center">{props.tab == "*" ? "Status" : props.tab == "0" || props.tab == "1" ? "Action" : ""}</th>
            </tr>
          </thead>
          {leads.map((i, index) => (
            <tbody>
              <tr >
                <td  className="text-center">{index + 1}</td>
                <td  className="text-center">{i.number}</td>
                <td  className="text-center">{i.pickuplocation}</td>
                <td  className="text-center">{i.droplocation}</td>
                <td  className="text-center">{moment(i.datetime).format("YYYY-MM-DD hh:mm:ss")}</td>
                <td  className="text-center">{i.totalfareprice}</td>
                <td  className="text-center">{i.totalfaredistance}</td>
                <td  className="text-center">{i.car}</td>
                <td  className="text-center">{props.tab == "*" ? i.status == 0 ? "Pending" : i.status == 1 ? "In-process" : i.status == 2 ? "Completed" : "Rejected" : props.tab == "0" ? <>
                  <Button color={i.carpool ? "info" : "success"} onClick={async (e) => {
                    e.preventDefault()
                    // setId(i.id)
                    // toggle3()
                    await onChangeStatus(i.id, 1)
                  }} size="sm">
                    Accept{i.carpool ? " Car Pool" : " Ride"}
                  </Button>
                  <Button
                    style={{ backgroundColor: "red" }} onClick={async (e) => {
                      e.preventDefault()
                      // navigate(`/edit-lead/${i.id}`)
                      // setId(i.id)
                      // toggle4()
                      await onChangeStatus(i.id, 3)
                    }} size="sm">
                    Reject
                  </Button></> : props.tab == "1" && <>  <Button color="success" onClick={async (e) => {
                    e.preventDefault()
                    // setId(i.id)
                    // toggle3()
                    await onChangeStatus(i.id, 2)
                  }} size="sm">
                    Mark as Completed
                  </Button></>}
                </td>
              </tr>
            </tbody>
          ))}
        </Table>
      ) : (
        <>No Record Found</>
      )}

      {/* <Modal size="lg" style={{maxWidth: '700px', width: '100%'}} isOpen={modal} toggle={toggle3} backdrop="static">
        <ModalHeader toggle={toggle3} className="adddeal-popup">
          Detailed View
        </ModalHeader>
        <ModalBody className="adddeal-popup">
          <Detailedview id={id} />
        </ModalBody>
      </Modal> */}

      {/* <Modal isOpen={modal2} size="lg" toggle={toggle4} className="text-center" backdrop="static">
        <ModalHeader toggle={toggle4} className="adddeal-popup text-center">
          Edit User Data
        </ModalHeader>
        <ModalBody className="adddeal-popup">
          <Dealform id={id}/>
        </ModalBody>
      </Modal> */}
    </div>
  );
}

export default Alluserdata;
