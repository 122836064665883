import React, { useEffect, useState } from "react";
import {
    Row,
    Col,
    Container,
    Input,
    Button,
    CardTitle,
    CardBody,
    CardSubtitle,
    CardText,
    Card,
} from "reactstrap";
import CarSelectionPopup from "./carselect";
import { getAllCities, getMatrix } from "../_helper/CallApi/citiesservice";
import suv1 from "../img/sedan1.jpeg";
import mini1 from "../img/mini.png";
import sedan1 from "../img/swift1.jpeg";
import moment from "moment"
import DateTime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaWhatsapp } from "react-icons/fa";
import { addNewPool, allPool } from "../_helper/CallApi/bookService";

const Booknow = () => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [selectedCar, setSelectedCar] = useState(null);
    const [loader, setLoader] = useState(false);
    const [pickupList, setPickupList] = useState([]);
    const [pool, setPool] = useState([]);
    const [dropList, setDropList] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [id, setId] = useState()
    // const [startDate, setStartDate] = useState(new Date());
    // const [time, setTime] = useState("12:00");
    const [mobile, setMobile] = useState("");
    const [pickupl, setPickupl] = useState("");
    const [dropLo, setDropLo] = useState("");
    const [datamap, setDataMap] = useState("");
    const [pickup, setPickup] = useState("");
    const [dropL, setDropL] = useState("");
    const [pickupapi, setPickupapi] = useState("");
    const [dropLapi, setDropLapi] = useState("");
    const [dt, setDt] = useState(0);
    const [du, setDu] = useState(0);
    const [selectedDate, setSelectedDate] = useState(new Date());
    useEffect(() => {
        (async () => {
            let response = await allPool();
            if (response?.data?.data?.length > 0) {
                setPool(response.data.data);
            } else {
                setPool([]);
            }

        })();
    }, [])
    useEffect(() => {
        (async () => {
            if (pickupapi && pickupapi.length > 3) {
                let response = await getAllCities("q=" + pickupapi);
                if (response?.data?.data?.length > 0) {
                    setPickupList(response.data.data.map((i) => i.cityname));
                } else {
                    setPickupList([]);
                }
            }
        })();
    }, [pickupapi]);
    useEffect(() => {
        (async () => {
            if (dropLapi && dropLapi.length > 3) {
                let response = await getAllCities("q=" + dropLapi);
                if (response?.data?.data?.length > 0) {
                    setDropList(response.data.data.map((i) => i.cityname));
                } else {
                    setDropList([]);
                }
            }
        })();
    }, [dropLapi]);

    useEffect(() => {
        setPickup(pickupl);
    }, [pickupl]);
    useEffect(() => {
        setDropL(dropLo);
    }, [dropLo]);

    const handleOpenPopup = async (e) => {
        e.preventDefault();
        setLoader(true)
        if (pickup && dropL) {
            let distance = await getMatrix(dropL, pickup);
            if (distance && distance.data?.status == 1) {
                setDt(distance.data.distance);
                setDu(distance.data.duration);
                setDataMap(distance.data.data);
                if (isPopupOpen) {
                    setIsPopupOpen(false);
                } else {
                    setIsPopupOpen(true);
                }
            } else {
                toast.error("please enter valid details")
            }
            setLoader(false)
        } else {
            setLoader(false)
            setIsPopupOpen(false);
        }
    };
    const handleChangeDate = (date) => {
        setSelectedDate(date);
    };
    const handleClosePopup = () => {
        setIsPopupOpen(false);
    };
    const handleSuggep = (value) => {
        setPickup(value);
        setPickupapi("");
        setPickupList([]);
    };

    const handlePick = (value) => {
        setPickup(value);
        setPickupapi(value);
    };

    const handleDrop = (value) => {
        setDropL(value);
        setDropLapi(value);
    };

    const handleSuggeL = (value) => {
        setDropL(value);
        setDropLapi("");
        setDropList([]);
    };

    const handleSelectCar = (carType) => {
        setSelectedCar(carType);
        // handleClosePopup();
    };

    const onBook = async (e) => {
        e.preventDefault()
        let obj = {
            number: mobile,
            id: id
        };
        let booking = await addNewPool(obj)
        if (booking?.data?.status == 1) {
            toast.success("booking in progress we will contact you in 5 min")
            // toast("booking in progress we will contact you in 5 min")
            setShowAlert(false)
        } else if (booking?.data?.status == 0) {
            toast.warn(booking?.data.message)
        } else {
            toast.error("service not avalable")
        }
    }
    return (
        <> {showAlert && (
            <div className="car-selection-popup-2">
                <div className="popup-content ">
                    {/* <div className="input-block"> */}
                    {/* <h6>Enter your mobile number for further<br /> ride confirmation</h6> */}
                    <label>Enter your mobile number</label>
                    <Input
                        type="text"
                        value={mobile}
                        onChange={(e) => setMobile(e.target.value)}
                        placeholder="Enter Mobile number"
                    />

                    {/* <div className="input-block"> */}
                    <div className="d-flex justify-content-between mt-3">
                        <Button
                            className="btn search-button"
                            style={{ backgroundColor: "red" }}
                            onClick={() => setShowAlert(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="btn search-button"
                            style={{ backgroundColor: "#00ed27" }}
                            onClick={onBook}
                        >
                            Continue
                        </Button>
                    </div>
                    {/* </div> */}
                    {/* </div> */}
                </div>
            </div >
        )}
            <div className="section-search">
                <div className="container">
                    <Row xs="1" md="2" sm="1">
                        <Col>
                            <div className="search-box-banner">
                                <h3 className="text-center">Book Now</h3>

                                <div className="input-block">
                                    <label>Pickup Location</label>
                                    <div className="group-img">
                                        <Input
                                            type="text"
                                            value={pickup}
                                            onChange={(e) => handlePick(e.target.value)}
                                            className="form-control"
                                            placeholder="Enter City, Airport, or Address"
                                        />
                                        {pickupList.length > 0 && (
                                            <ul className="suggestion-list">
                                                {pickupList.map((suggestion, index) => (
                                                    <li
                                                        key={index}
                                                        className="suggestion-item"
                                                        onClick={() => handleSuggep(suggestion)}
                                                    >
                                                        {suggestion}
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                        <span>
                                            <i className="feather-map-pin"></i>
                                        </span>
                                    </div>
                                </div>

                                <div className="input-block">
                                    <label>Pickup Date Time</label>
                                </div>
                                <div className="input-block-wrapp">
                                    <div className="input-block date-widget">
                                        <div className="group-img">
                                            <DateTime
                                                value={selectedDate}
                                                onChange={handleChangeDate}
                                                dateFormat="MM/DD/YYYY"
                                                // timeFormat="HH:mm"
                                                inputProps={{
                                                    className: "dateTimeInput",
                                                    style: {
                                                        background: "#fcfbfb",
                                                        borderRadius: 5,
                                                        border: "1px solid #f4f4f4",
                                                        boxShadow: "none",
                                                        color: "#201f1d",
                                                        width: 350
                                                    },
                                                }}
                                            />
                                            <span>
                                                <i className="feather-calendar"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div className="input-block">
                                    <label>Drop Location</label>
                                    <div className="group-img">
                                        <Input
                                            type="text"
                                            value={dropL}
                                            onChange={(e) => handleDrop(e.target.value)}
                                            className="form-control"
                                            placeholder="Enter City, Airport, or Address"
                                        />
                                        {dropList.length > 0 && (
                                            <ul className="suggestion-list">
                                                {dropList.map((suggestion, index) => (
                                                    <li
                                                        key={index}
                                                        className="suggestion-item"
                                                        onClick={() => handleSuggeL(suggestion)}
                                                    >
                                                        {suggestion}
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                        <span>
                                            <i className="feather-map-pin"></i>
                                        </span>
                                    </div>
                                </div>

                                {loader ? <div className="input-block mt-3">
                                    <div className="search-btn">
                                        <button
                                            className="btn search-button"
                                        >
                                            Loading
                                        </button>
                                    </div>
                                </div> : <div className="input-block mt-3">
                                    <div className="search-btn">
                                        <button
                                            className="btn search-button"
                                            onClick={handleOpenPopup}
                                        >
                                            Book
                                        </button>
                                    </div>
                                </div>}
                            </div>
                        </Col>
                        <Col>
                            <div className="search-box-banner" style={{ overflowY: "" }}>
                                <h3 className="text-center ">Car Pool</h3>
                                <div className="car-options22 carpooloption" style={{ overflowY: `${pool && pool.length > 0?"scroll":"hidden"}` }}>
                                    {pool && pool.length > 0 ? pool.map((i) => (<div className={`car-option-1`}>
                                        <div className="input-block-1">
                                            <img
                                                className="car-options-select"
                                                src={i.car.toLowerCase() == "mini" ? mini1 : i.car.toLowerCase() == "suv" ? suv1 : sedan1}
                                                alt="Mini"
                                            />
                                        </div>
                                        <div className="input-block-1 m-2" style={{ height: "50%" }}>
                                            <label><b>{i.car}</b></label><label className="total-fare">
                                                Pickup location {i.pickuplocation}<br /> Day {moment(i.datetime).calendar()}
                                            </label><label className="total-fare">
                                                Drop location {i.droplocation}
                                            </label>
                                            <label className="total-fare">
                                                Total fare is {i.totalfaredistance}
                                            </label>
                                            <p>Price {i.totalfareprice} Rs. per person</p>
                                        </div>

                                        <div className="input-block p-3 m-2">
                                            <div className="search-btn">
                                                <button
                                                    className="btn search-btn search-button"
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        setId(i.id)
                                                        setShowAlert(true)
                                                    }}
                                                >
                                                    Book
                                                </button>
                                            </div>
                                        </div>
                                    </div>)) : <>No Pool Available</>}

                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            {
                isPopupOpen && (
                    <div className="book-car-page z-index-1000">
                        <CarSelectionPopup
                            onClose={handleClosePopup}
                            onSelect={handleSelectCar}
                            dt={dt}
                            du={du}
                            pickup={pickup}
                            dropL={dropL}
                            datamap={datamap}
                            selectedDate={selectedDate}
                        />
                    </div>
                )
            }
        </>
    );
};

// const MyDatePicker = () => {
//     const [startDate, setStartDate] = useState(new Date());

//     return (
//       <DatePicker
//         selected={startDate}
//         onChange={(date) => setStartDate(date)}
//         dateFormat="dd/MM/yyyy"
//       />
//     );
//   };

export default Booknow;
