import React from "react";
import { Row, Col, Container, Label } from "reactstrap";
import Navbar2 from "../components/navbar";
import Footer2 from "../components/footer";
import bannerimg from '../img/path-makers/IMG-20240502-WA0018.jpg';
import bannerimg2 from '../img/path-makers/IMG-20240502-WA0022.jpg';
import bannerimg3 from '../img/path-makers/IMG-20240502-WA0020.jpg';

const About = () => {
  return (
    <>
      <Navbar2 />
      <Container className="text-center" style={{ fontSize: "large" }}>
        <h5 className="text-center m-5">
          Welcome to Path-Makers, your reliable partner in
          transportation solutions. At Path-Makers, we pride ourselves
          on providing convenient, safe, and efficient transportation services
          tailored to meet your needs.
        </h5>
        <Row className="mt-1" sm={1} md={2} xs={1} >
          <Col className="">
            <Label className="h2 bold">Our Mission</Label>
            <p className="text-center">
              With years of experience in the industry, we understand the
              importance of punctuality and reliability when it comes to
              transportation. Whether you're heading to a business meeting,
              catching a flight, or simply exploring the city, our fleet of
              modern vehicles and team of professional drivers are here to
              ensure you reach your destination comfortably and on time.
            </p>
          </Col> <Col className="text-center">
            <img src={bannerimg} style={{ width: "70%", height: "90%" }} alt="" />
          </Col>
        </Row>
        <Row sm={1} md={2} xs={1} >
          <Col className="carImg">
            <img src={bannerimg3} style={{ width: "70%", height: "90%" }} alt="" />
          </Col>
          <Col className="">
            <Label className="h2 bold">Our Vision</Label>
            <p className="text-center">
              What sets us apart is our commitment to customer satisfaction. We
              go above and beyond to make your journey with us a pleasant
              experience from start to finish. From easy booking processes to
              courteous and knowledgeable drivers, we strive to exceed your
              expectations every step of the way.
            </p>
          </Col>
          <Col className="carImg1">
            <img src={bannerimg3} style={{ width: "70%", height: "90%" }} alt="" />
          </Col>
        </Row>
        <Row sm={1} md={2} xs={1} >
         
          <Col className="">
            <Label className="h2 bold">What We Do</Label>
            <p className="text-center" >
              Safety is our top priority. All our drivers undergo rigorous
              background checks and training to ensure they meet the highest
              standards of professionalism and safety. Our vehicles are
              regularly inspected and maintained to guarantee your safety and
              comfort throughout your ride.
            </p>
          </Col> <Col className="">
            <img src={bannerimg2} style={{ width: "75%", marginTop: "0", height: "80%" }} alt="" />
          </Col>
        </Row>
      </Container>
      <Footer2 />
    </>
  );
};

export default About;