import React from "react";
// import { Row, Col, Container, Input, Button, CardTitle, CardBody, CardSubtitle, CardText, Card } from "reactstrap";
import servicesIcon from '../img/icons/services-icon-01.svg';
import servicesIcon1 from '../img/bg/service-right.svg';
import servicesIcon2 from '../img/icons/services-icon-02.svg';
import servicesIcon3 from '../img/icons/services-icon-03.svg';



const Howitwork = () => {
  return (
    <>
    <section className="section services">
            <div className="service-right">
                <img src={servicesIcon1} className="img-fluid" alt="services right"/>
            </div>
            <div className="container">

                <div className="section-heading">
                    <h2>How It Works</h2>
                    <p>Discover how it works with our simple steps, making booking your ride straightforward and effortless</p>
                </div>

                <div className="services-work">
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-12" >
                            <div className="services-group">
                                <div className="services-icon border-secondary">
                                    <img className="icon-img bg-secondary" src={servicesIcon}
                                        alt="Choose Locations"/>
                                </div>
                                <div className="services-content">
                                    <h3>1. Choose Locations</h3>
                                    <p>Select your locations swiftly with our user-friendly interface, ensuring a smooth start to your journey.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-12" data-aos=" ">
                            <div className="services-group">
                                <div className="services-icon border-warning">
                                    <img className="icon-img bg-warning" src={servicesIcon2}
                                        alt="Choose Locations"/>
                                </div>
                                <div className="services-content">
                                    <h3>2. Pick-Up Locations</h3>
                                    <p>Choose your pick-up location seamlessly with our intuitive map view, ensuring a swift start to your ride.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-12" data-aos=" ">
                            <div className="services-group">
                                <div className="services-icon border-dark">
                                    <img className="icon-img bg-dark" src={servicesIcon3}
                                        alt="Choose Locations"/>
                                </div>
                                <div className="services-content">
                                    <h3>3. Book your Car</h3>
                                    <p>Book your car easily with our streamlined booking process, making your ride reservation quick and hassle-free</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </section>

    </>
  );
};

export default Howitwork;