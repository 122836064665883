import React from "react";
import { Container, Row, Col } from "reactstrap";
import { TbBrandBooking } from "react-icons/tb";
import { MdOutlineSupportAgent } from "react-icons/md";
import { FaCarSide } from "react-icons/fa";
import { GrUserManager } from "react-icons/gr";
import { AiFillSafetyCertificate } from "react-icons/ai";
import { MdSpatialTracking } from "react-icons/md";
import Services from "./ourservices";
import { GiWaterBottle } from 'react-icons/gi';


function Whypathmaker() {

  return (
    <>
    <Services/>
      <Container>
        <h3 className="text-center">Why Path-Makers ?</h3>
        <Row>
          <Col><TbBrandBooking className="h3 m-4" />Instant Booking</Col>
          <Col><MdOutlineSupportAgent className="h3 m-4" />Everytime Availability</Col>
          <Col><GiWaterBottle className="h3 m-4" />complementary water services</Col>
          <Row>

          </Row>
          <Col><GrUserManager className="h3 m-4" />Trained Drivers</Col>
          <Col><AiFillSafetyCertificate className="h3 m-4" />Women Safety</Col>
          <Col><MdSpatialTracking className="h3 m-4" />Reliable Customer Support.</Col>
        </Row>
      </Container>

    </>
  );
}

export default Whypathmaker;