import React from "react";
// import { Row, Col, Container, Input, Button, CardTitle, CardBody, CardSubtitle, CardText, Card } from "reactstrap";
import servicesIcon from '../img/car-right.png';



const Mainfront = () => {
    return (
        <>
            <section className="banner-section banner-slider">
                <div className="container">
                    <div className="home-banner">
                        <div className="row align-items-center">
                            <div className="col-lg-6" data-aos="">
                                <p className="explore-text">Experience the best in class<br />
                                    Experience PATH-MAKERS!!</p>
                                <h1>Find Your Best <br />
                                    <span>One way One fare</span>
                                </h1>
                                <p>Find your best fare with our one-way pricing. Enjoy transparent, affordable rides tailored to your needs.</p>
                                {/* <div className="view-all">
                                    <a href="listing-grid.html" className="btn btn-view d-inline-flex align-items-center">View
                                        all Cars <span><i className="feather-arrow-right ms-2"></i></span></a>
                                </div> */}
                            </div>
                            <div className="col-lg-6" data-aos="">
                                <div className="banner-imgs">
                                    {/* <img src={servicesIcon} className="img-fluid aos" alt="bannerimage" /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
};

export default Mainfront;