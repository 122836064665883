import React, { useEffect, useState } from "react";
import Navbar2 from "../components/navbar";
import Footer2 from "../components/footer";
import Booknow from "../components/booknow";
import Whypathmaker from "../components/whypathmaker";
import Howitwork from "../components/howitwork";
import Mainfront from "../components/mainfront";
import "../css/style.css"
import "../css/bootstrap.min.css"
import "../css/feather.css"
import "../css/owl.carousel.min.css"
import "../css/style.css"
import {
  GetCountries,
  GetState,
  GetCity,
  GetLanguages, //async functions
} from "react-country-state-city";
import { addCity, getAllCities } from "../_helper/CallApi/citiesservice";
import { FaWhatsapp } from "react-icons/fa";
import { Link } from "react-router-dom";



function Home() {
  return (
    <>

      <Link to="https://api.whatsapp.com/send?phone=+918279506479&amp;text=Chat with Path-Makers Executive" className="float" target="_blank">
        <FaWhatsapp tag={Link} to="https://api.whatsapp.com/send?phone=+918279506479&amp;text=Chat with Path-Makers Executive" target="_blank" className="whatsapp-icon" />
      </Link>

      <Navbar2 />
      <Mainfront />
      <Booknow />
      <Howitwork />
      <Whypathmaker />
      <Footer2 />
    </>
  );
}

export default Home;