import React, { useEffect, useState } from "react";
import Navbar2 from "../components/navbar";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Alluserdata from "../components/all_user_data";
import { getUserlocal } from "../_helper/ApiConfig/API_AUTH";
import { useBeforeUnload, useNavigate } from "react-router-dom";
import { changePassword, exportData, getNewRide } from "../_helper/CallApi/bookService";
import CarPrices from "../components/carprices";
import CarPool from "../components/carpool";
import { toast } from "react-toastify";
import { URL_CONFIG } from "../_constant/Config/URL_CONFIG";
import moment from "moment";
import {
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Label,
} from "reactstrap";
import DateTime from "react-datetime";

function Alldetails() {
  const [tab, setTab] = useState("0");
  const [searchvalue, setSearchvalue] = useState();
  const [reload, setReload] = useState(0);
  const [newrides, setNewrides] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [startDate, setStartDate] = useState(
    moment(new Date()).startOf("month").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).endOf("month").format("YYYY-MM-DD")
  );
  const navigate = useNavigate();
  const [oldPassword, setOldPassword] = useState();
  const [password, setPassword] = useState();
  const [repeat, setRepeat] = useState();

  useBeforeUnload(() => {
    if (!getUserlocal()) {
      navigate("/home");
    }
  }, []);
  const onChange = async() => {
    if(!oldPassword||!password||!repeat){
     return toast.error("all fields are require")
    }
    if(password!=repeat){
     return toast.error("both fields values should be same (new password or repeat new password)")
    }
    let res=await changePassword(`?p=${oldPassword}&n=${password}&r=${repeat}`)
    console.log(res);
    if(res?.status==0){
      return toast.error(res.message)
    }
    if(res?.status==1){
       toast.success(res.message)
      localStorage.clear()
      return
    }
  };
  useEffect(() => {
    // Function to fetch data
    const fetchData = () => {
      // Perform your data fetching or any other operation here
      // For demonstration, let's just set some dummy data
      (async () => {
        const response = await getNewRide();
        console.log(response);
        if (response) {
          setNewrides(response.count);
        }
      })();
      // setData('Data fetched at: ' + new Date().toLocaleTimeString());
    };

    fetchData();

    const interval = setInterval(fetchData, 10000);

    return () => clearInterval(interval);
  }, [reload]);

  const downloadFile = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("authToken");
    var a = document.createElement("a");
    // toDataURL defaults to png, so we need to request a jpeg, then convert for file download.
    a.href = `${URL_CONFIG.DEV_URL}export-data?token=${token}&startDate=${startDate}&endDate=${endDate}`;
    a.download = "data.xlsx";
    a.target = "blank";
    a.click();
  };
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Booking Details</title>
        </Helmet>
      </HelmetProvider>
      <Navbar2 />
      <div className="m-3 d-flex justify-content-wrap">
        <Button
          className={`btn search-button m-2`}
          style={{ backgroundColor: `${tab == "0" ? "green" : "gray"}` }}
          onClick={(e) => {
            e.preventDefault();
            setTab("0");
          }}
        >
          New Rides
          <span style={{ color: "green", backgroundColor: "yellow" }}>
            {newrides}
          </span>
        </Button>
        <Button
          className="all-btn m-2"
          style={{ backgroundColor: `${tab == "1" ? "green" : "gray"}` }}
          onClick={(e) => {
            e.preventDefault();
            setTab("1");
          }}
        >
          In-process Rides
        </Button>
        <Button
          className="all-btn m-2"
          style={{ backgroundColor: `${tab == "2" ? "green" : "gray"}` }}
          onClick={(e) => {
            e.preventDefault();
            setTab("2");
          }}
        >
          Completed Rides
        </Button>
        <Button
          className="all-btn m-2"
          style={{ backgroundColor: `${tab == "*" ? "green" : "gray"}` }}
          onClick={(e) => {
            e.preventDefault();
            setTab("*");
          }}
        >
          All Rides
        </Button>
        <Button
          className="all-btn m-2"
          style={{ backgroundColor: `${tab == "3" ? "green" : "gray"}` }}
          onClick={(e) => {
            e.preventDefault();
            setTab("3");
          }}
        >
          Rejected Rides
        </Button>

        <Button
          className="all-btn m-2"
          style={{ backgroundColor: `${tab == "4" ? "green" : "gray"}` }}
          onClick={(e) => {
            e.preventDefault();
            setTab("4");
          }}
        >
          Cars with Prices
        </Button>

        <Button
          className="all-btn m-2"
          style={{ backgroundColor: `${tab == "5" ? "green" : "gray"}` }}
          onClick={(e) => {
            e.preventDefault();
            setTab("5");
          }}
        >
          Car Pool
        </Button>
        <Button
          className="all-btn m-2"
          style={{ backgroundColor: `${tab == "6" ? "green" : "gray"}` }}
          onClick={(e) => {
            e.preventDefault();
            setShowAlert(true);
            setTab("6");
          }}
        >
          export-excel
        </Button>
        <Button
          className="all-btn m-2"
          style={{ backgroundColor: `${tab == "7" ? "green" : "gray"}` }}
          onClick={(e) => {
            e.preventDefault();
            setTab("7");
          }}
        >
          Change Password
        </Button>
      </div>
      {tab == "4" ? (
        <>
          <CarPrices />
        </>
      ) : (
        <>
          {tab == "5" ? (
            <>
              <CarPool />
            </>
          ) : (
            <>
              {tab == "7" ? (
                <>
                  (
                  <div className="car-selection-popup-3">
                    <div className="popup-content-3">
                      {/* <div className="popup-content-2 ">
                    <div className="input-block">
                      <label>Old Password</label>
                    </div>
                    <div className="input-block-wrapp">
                      <div className="input-block date-widget">
                        <div className="group-img">
                          <Input type={"text"}></Input>
                        </div>
                      </div>
                    </div>
                    <div className="input-block">
                      <label>End Date</label>
                    </div>
                    <div className="input-block-wrapp">
                      <div className="input-block date-widget">
                        <div className="group-img">
                          <DateTime
                            // className="form-control "
                            value={endDate}
                            onChange={(e) =>
                              setEndDate(moment(e).format("YYYY-MM-DD"))
                            }
                            dateFormat="DD-MM-YYYY"
                            timeFormat={false}
                            closeOnClickOutside
                            closeOnSelect
                            inputProps={{
                              className: "dateTimeInput",
                              style: {
                                background: "#fcfbfb",
                                borderRadius: 5,
                                border: "1px solid #f4f4f4",
                                boxShadow: "none",
                                color: "#201f1d",
                              },
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between mt-3">
                      <Button
                        className="btn search-button"
                        style={{ backgroundColor: "red" }}
                        onClick={() => setShowAlert(false)}
                      >
                        back
                      </Button>
                      <Button
                        className="btn search-button"
                        style={{ backgroundColor: "#00ed27" }}
                        onClick={downloadFile}
                      >
                        continue
                      </Button>
                    </div>
                  </div> */}
                      <Label>Enter old password</Label>
                      <Input
                        type="password"
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                        placeholder="Enter old password"
                      />
                      <Label className="mt-1">Enter new password</Label>
                      <Input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Enter new password"
                      />
                      <Label className="mt-1">Enter new password again</Label>
                      <Input
                        type="password"
                        value={repeat}
                        onChange={(e) => setRepeat(e.target.value)}
                        placeholder="Enter new password again"
                      />
                      <div className="d-flex justify-content-between mt-3">
                        <Button
                          className="btn search-button"
                          style={{ backgroundColor: "red" }}
                          onClick={() => setShowAlert(false)}
                        >
                          No
                        </Button>
                        <Button
                          className="btn search-button"
                          style={{ backgroundColor: "#00ed27" }}
                          onClick={onChange}
                        >
                          Yes
                        </Button>
                      </div>
                    </div>
                  </div>
                  )
                </>
              ) : (
                <>
                  {showAlert && (
                    <div className="car-selection-popup">
                      <div className="popup-content-2 ">
                        <div className="input-block">
                          <label>Start Date</label>
                        </div>
                        <div className="input-block-wrapp">
                          <div className="input-block date-widget">
                            <div className="group-img">
                              <DateTime
                                // className="form-control "
                                value={startDate}
                                onChange={(e) => {
                                  console.log(e);
                                  setStartDate(moment(e).format("YYYY-MM-DD"));
                                }}
                                dateFormat="DD-MM-YYYY"
                                timeFormat={false}
                                closeOnClickOutside
                                closeOnSelect
                                inputProps={{
                                  className: "dateTimeInput",
                                  style: {
                                    background: "#fcfbfb",
                                    borderRadius: 5,
                                    border: "1px solid #f4f4f4",
                                    boxShadow: "none",
                                    color: "#201f1d",
                                  },
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="input-block">
                          <label>End Date</label>
                        </div>
                        <div className="input-block-wrapp">
                          <div className="input-block date-widget">
                            <div className="group-img">
                              <DateTime
                                // className="form-control "
                                value={endDate}
                                onChange={(e) =>
                                  setEndDate(moment(e).format("YYYY-MM-DD"))
                                }
                                dateFormat="DD-MM-YYYY"
                                timeFormat={false}
                                closeOnClickOutside
                                closeOnSelect
                                inputProps={{
                                  className: "dateTimeInput",
                                  style: {
                                    background: "#fcfbfb",
                                    borderRadius: 5,
                                    border: "1px solid #f4f4f4",
                                    boxShadow: "none",
                                    color: "#201f1d",
                                  },
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between mt-3">
                          <Button
                            className="btn search-button"
                            style={{ backgroundColor: "red" }}
                            onClick={() => setShowAlert(false)}
                          >
                            back
                          </Button>
                          <Button
                            className="btn search-button"
                            style={{ backgroundColor: "#00ed27" }}
                            onClick={downloadFile}
                          >
                            continue
                          </Button>
                        </div>
                      </div>

                      {/* </div> */}
                      {/* </div> */}
                    </div>
                  )}
                  <Alluserdata
                    tab={tab}
                    searchvalue={searchvalue}
                    setReload={setReload}
                    reload={reload}
                    newrides={newrides}
                  />
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

export default Alldetails;
