import React, { useState } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { Link } from "react-router-dom";
import logo from '../img/path-makers/logo2.jpg';


const Navbar2 = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle2 = () => setIsOpen(!isOpen);

  return (
    <div>

      <Navbar
        className="shadow navbar-dark navbaar222"
        fixed="top"
        sticky="top"
        // color="light"
        light
        expand="md"
      >
        <NavbarBrand tag={Link} to="/home">
          <img width="40px" src={logo} alt="Path-Makers" />
          <span className="">Path-Makers</span>
        </NavbarBrand>
        <NavbarToggler onClick={toggle2} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-auto" navbar>
            <NavItem className="nav-links">
              <NavLink tag={Link} to="/home">Home</NavLink>
            </NavItem>
            <NavItem className="nav-links">
              <NavLink tag={Link} to="/about">About us</NavLink>
            </NavItem>
            <NavItem className="nav-links">
              <NavLink tag={Link} to="/contact">Contact</NavLink>
            </NavItem>
            <NavItem className="nav-links carImg1">
              <NavLink tag={Link} to="/login">Admin</NavLink>
            </NavItem>

          </Nav>


        </Collapse>
      </Navbar>
    </div>
  );
};

export default Navbar2;